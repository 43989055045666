@import "animate.css/animate.css";
@import "bootstrap/scss/bootstrap.scss";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "@zupper/common/assets/css/variables";
@import "@zupper/common/assets/css/commons";
@import "@zupper/common/assets/css/header";
@import "@zupper/common/assets/css/footer";
@import "@zupper/common/assets/css/products";
@import "placeholder-loading/src/scss/placeholder-loading";

* {
  outline: none !important;
}

button,
a {
  outline: none !important;
}

p {
  margin: 0;
}

.popover {
  max-width: none !important;
}

div.popover-body {
  padding: 0;
  color: #666;
}

/*ARROW multidestinos*/

.finish-slice-search
  app-passengers
  ngb-popover-window.bs-popover-bottom
  > .arrow {
  margin-left: 0.2rem;
}

/*TEXTBOX roundTrip & oneWay*/

app-find-airports ngb-popover-window {
  margin-left: 4.5rem;
}

app-find-airports ngb-popover-window.bs-popover-bottom > .arrow {
  left: 0 !important;
  margin: 0 0 0 0.2rem !important;
}

/*TEXTBOX passageiros roundTrip & oneWay */

app-passengers ngb-popover-window {
  margin-left: 4rem;
}

app-passengers ngb-popover-window.bs-popover-bottom > .arrow {
  left: 0 !important;
  margin: 0 0 0 0.2rem !important;
}

.mobile-only {
  display: none;
}

.ngx-slider {
  span.ngx-slider-selection,
  span.ngx-slider-pointer {
    background: #008c99;
  }
  .ngx-slider-pointer.ngx-slider-active:after {
    background-color: white !important;
  }
}

.multiselect-zupper {
  div.multiselect-dropdown {
    font-size: 0.9rem;
    span.dropdown-btn {
      background-color: #eee !important;
      border: 1px solid #e8e8e8 !important;
      color: #888 !important;
      padding: 0.5rem !important;
      border-radius: 4px 4px 0 0 !important;
      span.selected-item {
        background: #008c99 !important;
        border-radius: 1rem !important;
        padding: 0 1rem !important;
        border: none !important;
        text-transform: lowercase;
        margin-bottom: 0.3rem !important;
      }
      & > span:last-child {
        display: grid;
        align-items: center;
        min-height: 1.5rem;
        justify-items: center;
      }
    }
    div.dropdown-list {
      border-radius: 0 !important;
      position: relative;
      box-shadow: none;
      margin-top: 0;
    }
  }
}

ngb-datepicker {
  .ngb-dp-day.disabled {
    background: #eee;
    color: #ccc;
    span {
      text-decoration: line-through;
    }
  }
  .ngb-dp-weekday {
    font-size: 0.9em;
    color: gray;
    font-weight: bold;
    font-style: normal;
  }
  .btn-link {
    color: #008c99;
    outline-color: #008c99;
    &:hover {
      border-color: #008c99;
      color: #008c99;
    }
  }
}

.ph-item {
  margin: 0;
  padding: 1rem 0.5rem;
  border: none;
  .loading-container {
    display: grid;
    grid-template-columns: 13rem 2fr 1fr;
    grid-gap: 1rem;
    height: 10rem;
    & > div:last-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    div.img,
    div.row,
    div.button {
      height: 1.3rem;
      background: #ced4da;
      border-radius: 2px;
    }
    div.img {
      height: 100%;
    }
    div.row {
      margin-bottom: 1.5rem;
    }
    div.button {
      height: 2.5rem;
      border-radius: 1rem;
      margin-bottom: 1.5rem;
    }
  }
}
.desktop-only-plane {
  margin-left: 1rem;
  margin-top: 1rem;
  i {
    transform: rotate(-45deg);
  }
}
.desktop-only {
  margin-left: 1rem;
  margin-top: 1rem;
}

@media only screen and (max-width: 1023px) {
  .desktop-only {
    display: none !important;
  }
  .desktop-only-plane {
    display: none !important;
  }
  .mobile-only {
    display: block;
  }
  div.popover-body {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: white;
    padding: 2rem 1rem;
    .button-close-popup {
      background: #008c99;
      padding: 0.6rem 3rem;
      border-radius: 5rem;
      border: none;
      color: white;
      margin: 1rem auto;
      font-size: 1rem;
      display: block;
    }
  }
  ngb-popover-window,
  ngb-typeahead-window {
    will-change: unset !important;
    transform: unset !important;
  }
  ngb-typeahead-window {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    margin-bottom: 1rem !important;
  }
  ngb-datepicker {
    width: 100%;
    .ngb-dp-content.ngb-dp-months > div:not(:nth-child(1)) {
      display: none;
    }
    .ngb-dp-month,
    .ngb-dp-weekday,
    .ngb-dp-day {
      width: 100%;
      height: 100%;
      & > span {
        display: grid !important;
        align-items: center !important;
        height: 100% !important;
        width: 100% !important;
      }
    }
  }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
  ngb-datepicker {
    width: 100%;
    .ngb-dp-content.ngb-dp-months > div:not(:nth-child(1)) {
      display: none;
    }
    .ngb-dp-month,
    .ngb-dp-weekday,
    .ngb-dp-day {
      width: 100%;
    }
    .ngb-dp-day {
      height: 2.5rem;
    }
  }
}
